<template>
  <div class="block">
    <el-page-header @back="dynamic" content="车辆配置" style="margin-bottom: 15px;">
    </el-page-header>
    <template>
      <el-button type="text" @click="outerVisible = true">
        <el-button type="primary" size="mini">新增</el-button>
      </el-button>

      <el-dialog title="添加" :visible.sync="outerVisible">
        <el-form :model="form">
          <el-form-item label="车辆类型" :label-width="formLabelWidth"  class="w-50" size="small">
            <el-select v-model="form.carName" placeholder="请选择">
              <el-option label="试驾车" value="试驾车"></el-option>
              <el-option label="代步车" value="代步车" ></el-option>
              <el-option label="公务车" value="公务车" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车辆型号" :label-width="formLabelWidth"  class="w-50" size="small">
            <el-input v-model="form.carType" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="车牌号" :label-width="formLabelWidth"  class="w-50" size="small">
            <el-input v-model="form.carNum" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="车架号" :label-width="formLabelWidth"  class="w-50" size="small">
            <el-input v-model="form.carVin" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="注册日期" :label-width="formLabelWidth"  class="w-50" size="small">
            <el-date-picker
                v-model="form.regDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="保险日期" :label-width="formLabelWidth"  class="w-50" size="small">
            <el-date-picker
                v-model="form.insure"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <el-dialog
            width="20%"
            title="警告"
            :visible.sync="innerVisible"
            append-to-body>
          <span>您将新增一条记录，请确认！</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="innerVisible = false" size="small">取 消</el-button>
            <el-button type="primary" @click="resetForm();innerVisible = false;outerVisible = false;" size="small">确 定</el-button>
          </span>
        </el-dialog>
        <div slot="footer" class="dialog-footer">
          <el-button @click="outerVisible = false" size="small">取 消</el-button>
          <el-button slot="reference" type="primary" @click="innerVisible = true" size="small">提交</el-button>
        </div>
      </el-dialog>
    </template>
    <el-table
        :data="carData"
        style="width: 100%; margin: auto"
        :cell-style="{padding: '0'}"
        height="456"
        max-height="456">
      <el-table-column
          fixed
          prop="id"
          label="序号"
          width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column
          prop="carNum"
          label="车牌号"
          width="120">
      </el-table-column>
      <el-table-column
          prop="carType"
          label="类型"
          width="100">
      </el-table-column>
      <el-table-column
          prop="carName"
          label="车型"
          width="100">
      </el-table-column>
      <el-table-column
          prop="carVin"
          label="车架号">
      </el-table-column>
      <el-table-column
          prop="regDate"
          label="注册日期"
          width="100">
      </el-table-column>
      <el-table-column
          prop="inspect"
          label="年审到期"
          width="120">
      </el-table-column>
      <el-table-column
          prop="insure"
          label="保险到期"
          width="120">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope1">
          <el-button
              @click.native.prevent="open(scope1.row.carId)"
              style="padding: 2px; margin: 5px">
            <i class="el-icon-delete"></i>
          </el-button>
          <el-button
              @click.native.prevent="queryCarById(scope1.row.carId);"
              style="padding: 2px; margin: 5px 0px">
            <i class="el-icon-edit"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="编辑" :visible.sync="outerCar">
      <el-form :model="editForm">
        <el-form-item label="车辆类型" :label-width="formLabelWidth"  class="w-50" size="small">
          <el-select v-model="editForm.carName" placeholder="请选择">
            <el-option label="试驾车" value="试驾车"></el-option>
            <el-option label="代步车" value="代步车" ></el-option>
            <el-option label="公务车" value="公务车" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车辆型号" :label-width="formLabelWidth"  class="w-50" size="small">
          <el-input v-model="editForm.carType" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="车牌号" :label-width="formLabelWidth"  class="w-50" size="small">
          <el-input v-model="editForm.carNum" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="车架号" :label-width="formLabelWidth"  class="w-50" size="small">
          <el-input v-model="editForm.carVin" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="注册日期" :label-width="formLabelWidth"  class="w-50" size="small">
          <el-date-picker
              v-model="editForm.regDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="保险日期" :label-width="formLabelWidth"  class="w-50" size="small">
          <el-date-picker
              v-model="editForm.insure"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <el-dialog
          width="20%"
          title="警告"
          :visible.sync="innerCar"
          append-to-body>
        <span>您将新增一条记录，请确认！</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="innerCar = false" size="small">取 消</el-button>
            <el-button type="primary" @click="editCar();innerCar = false;outerCar = false;" size="small">确 定</el-button>
          </span>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="outerCar = false" size="small">取 消</el-button>
        <el-button slot="reference" type="primary" @click="innerCar = true" size="small">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "car",
  data() {
    return {
      outerCar: false,
      outerVisible: false,
      innerCar: false,
      innerVisible: false,
      form: {
        carName: '',
        carType: '',
        carNum: '',
        carVin: '',
        regDate: '',
        insure: ''
      },
      editForm: {
        carName: '',
        carType: '',
        carNum: '',
        carVin: '',
        regDate: '',
        insure: ''
      },
      respMsg:[],
      formLabelWidth: '120px',
      carData: []
    }
  },
  mounted() {
    this.getCarData()
  },
  methods: {
    resetForm() {
      this.$http({
        method: 'post',
        url: '/api/car/insertCar',
        data: this.form
      }).then((res)=>{
        alert(res.data.msg)
        if (res.data.status) {
          this.form = {}
          this.getCarData()
        }
      })
    },
    getCarData() {
      this.$http({
        method: 'get',
        url: '/api/car/getCarList',
        data: []
      }).then((res)=>{
        this.carData = res.data.data
      })
    },
    dynamic() {
      this.$router.push("dynamic")
    },
    queryCarById(carId) {
      this.$http({
        method: 'get',
        url: '/api/car/queryCarById/' + carId,
        data: ''
      }).then((res) =>{
        this.editForm = res.data.data
        this.outerCar = true
      })
    },
    open(carId) {
      this.$confirm('删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          method: 'delete',
          url: '/api/car/deleteCarById/' +carId
        }).then((res) =>{
          this.$message({
            type: 'success',
            message: res.data.msg
          });
          this.getCarData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      });
    },
    editCar() {
      this.$http({
        method: 'put',
        url: '/api/car/updateCarById',
        data: this.editForm
      }).then((res) =>{
        this.$message({
          type: 'success',
          message: res.data.msg
        });
        this.getCarData()
      }).catch((res)=>{
        this.$message({
          type: 'info',
          message: res.data.msg
        });
      })
    }
  }
};
</script>

<style scoped>
.w-50 {
  width: 50%;
  margin-left: 18%;
}
.el-table {
  padding: 0;
}
   /deep/ th {
     padding: 0 ;
   }
/deep/ td {
  padding: 0 ;
}
</style>